import React from 'react';

import './ContactManagement.scss';

import {BrowserRouter as Router, Route, Link } from 'react-router-dom';

import useFetch from '../../hooks/useFetch/index';

import ContactEditor from './contact-editor/ContactEditor';

const ContactManagement = () => {

    return (
        <>
            <div className="main-editor">
                <ContactEditor/>
            </div>
        </>
    )
}

export default ContactManagement;

import React, { useState } from 'react';
import NewsSummary from '../news-summary/NewsSummary.js';

import './News.scss';

import { Link } from 'react-router-dom';

import useFetch from '../../../hooks/useFetch/index';

import fakeData from '../../../data/news.js';

import { useTranslation } from 'react-i18next';

const News = (props) => {

    const { t } = useTranslation();

    const { apiData, isLoading } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}articles`,
        method: 'get'
    });


    if(isLoading) {
        return <div>is loading</div>
    }

    return (
        <div className="news">
            <div className="container">
                <h4>{t('news.summary')}</h4>
                <div className="news-summaries">
                {
                    apiData.slice(0,4).map((news, index) => {
                        return <NewsSummary news={news} />
                    })
                }
                </div>
                <Link to={"/news"} className="btn-type-1">{t('button.readmore')}</Link>
            </div>
        </div>
    )
}

export default News;

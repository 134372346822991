import './Navbar.scss';
import React, { useContext, useState, useEffect } from 'react';
import Language from './language/Language.js';
import Categories from './categories/Categories.js';
import SearchBox from './searchbox/SearchBox.js';

import { Link } from 'react-router-dom';

import { CategoryContext } from '../../contexts/CategoryContext';

const Navbar = () => {
    const [ isCollapsed, setIsCollapsed] = useState(false); 

    const context = useContext(CategoryContext);

    useEffect(() => {
        setIsCollapsed(false);
    }, [context, window.location.pathname])

    const toggleIsCollapsed = () => {
        setIsCollapsed(!isCollapsed);	
    }

    return (
        <div className="navbar">
            <div className="container">
                <div className="logo">
                    <Link to="/"><img src={process.env.PUBLIC_URL + "/images/logo.svg"} /></Link>
                </div>
                <Language />
                <SearchBox />
                <div className={"items" + ((isCollapsed) ? " sidebar-collapsed" : "")}>
                <Categories />
                </div>
                    <a className={"left-sidebar-toggle" + ((isCollapsed) ? " sidebar-collapsed" : "")}
                     onClick={toggleIsCollapsed} >
                        <i class="fa fa-bars fa-2x"></i>
                    </a>
            </div>
        </div>
    )
}

export default Navbar;

import React, { useState } from 'react';
import axios from 'axios';
import './Contact.scss';

import { useTranslation } from 'react-i18next';

const Contact = () => {

    const [ inputField, setInputField ] = useState();

    const { t } = useTranslation();

    const inputsHandle = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const submitHandle = async () => {
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_HOST}customer_contacts`,
            data: inputField
        })

        window.location.reload();
    }

    return (
        <div className= "contact wow fadeIn">	
            <div className="background"/>
            <div className="container">
                <div className="contact-image">
                    <img src= {process.env.PUBLIC_URL + "/contact.jpg"}/>
                </div>
                <div className="content">
                    <h4>{t('contact.header')}</h4>
                    <p className="contact-note">{t('contact.note')}</p>
                    <div className="form-input">
                    <input
                    type="text"
                    name="fullName"
                    placeholder={t('contact.input.fullname')}	
                    onChange={inputsHandle}/>	
                    <input
                    type="text"
                    name="phone"
                    placeholder={t('contact.input.phone')}
                    onChange={inputsHandle}/>	
                    <input
                    type="text"
                    name="email"
                    placeholder={t('contact.input.email')}
                    onChange={inputsHandle}/>	
                    <button className="btn-type-1" onClick={submitHandle}>{t('button.send')}</button>
                    </div>
                </div>	
            </div>
        </div>
    )
}

export default Contact;

import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import useFetch from '../../hooks/useFetch/index';

import './ServiceDetails.scss';

const ServiceDetails = () => {

    const { id } = useParams();

    const { apiData, isLoading } = useFetch({
        url:`${process.env.REACT_APP_API_HOST}services/${id}`,
        method: 'get'
    })

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    if(isLoading || apiData.length === 0) {
        return ( <div>loading</div> )
    }
    return (
        <div className="service-details wow fadeIn">
            <div className="headers">
                <div className="container">
                    <div className="content">
                        {
                            (apiData[0].title) ?
                            <h3>{apiData[0].title}</h3> 
                            : <></>
                        }
                        {
                            (apiData[0].quote) ?
                            <p>{apiData[0].quote}</p> 
                            : <></>
                        }
                    </div>
                    <div className="serviceImage">
                        <img src={process.env.REACT_APP_API_STATIC + '/' + apiData[0].image_path }/>
                    </div>
                </div>
                <div className="star-breakline revert" />
            </div>
        <div className={'long-description container' + (apiData[0].long_description ? "" : " none-display")}>
                <p>{apiData[0].long_description}</p>
             </div>
            <div className="sub-title container">
                <h3>{apiData[0].sub_title}</h3>
            </div>
            <div className="sub-services">
                <div className="container">
                {apiData[0].subServices.map((subService, index) => {
                    return (
                        <div className="sub-service wow fadeIn">
                            <div className="content">
                                <p>{subService.content}</p>
                            </div>
                            <div className="sub-service-image">
                                <img src={process.env.REACT_APP_API_STATIC + '/' + subService.image_path} />
                            </div>
                        </div>
                    )
                })} 
                </div>

                <div className="star-breakline" />
            </div>
        </div>
    )
}

export default ServiceDetails;

import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import useFetch from '../../hooks/useFetch/index';

import './IntroductionDetails.scss';

const IntroductionDetails = () => {

    const { apiData, isLoading } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}static_data/introduction`,
        method: 'get'
    })


    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    if(isLoading || apiData.length === 0) {
        return ( <div>loading</div> )
    }
    return (
        <div className="introduction-details">
            <div className="headers container">
                <div className="introductionImage">
                    <img src={process.env.REACT_APP_API_STATIC + '/' + apiData[0].image_path }/>
                </div>
            </div>
            <div className="title container">
                <h3>{apiData[0].title}</h3>
            </div>
            <div className="sub-introductions">
                <div className="container">
                {apiData[0].subArticles.map((subIntroduction, index) => {
                    return (
                        <div className="sub-introduction">
                            <div className="content">
                                <p>{subIntroduction.content}</p>
                            </div>
                            <div className="sub-introduction-image">
                                <img src={process.env.REACT_APP_API_STATIC + '/' + subIntroduction.image_path} />
                            </div>
                        </div>
                    )
                })} 
                </div>
            </div>
        </div>
    )
}

export default IntroductionDetails;

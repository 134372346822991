import React, { useEffect, useState } from 'react';
import NewsSummary from '../news-summary/NewsSummary.js';

import { Link, useLocation } from 'react-router-dom';

import './NewsMain.scss';

import useFetch from '../../../hooks/useFetch/index';


import { useTranslation } from 'react-i18next';

const NewsMain = (props) => {

    const { t } = useTranslation();

    const search = useLocation().search;

    const page = new URLSearchParams(search).get("page") || "1";

    const size = new URLSearchParams(search).get("size") || "8";

    const keyword = new URLSearchParams(search).get("keyword");

    const [ articles, setArticles ] = useState([]);

    const [totalPage, setTotalPage ] = useState();

    const { apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}articles/findByPage?page=${page}&size=${size}${keyword ? ('&keyword=' + keyword) : ""}`,
        method: 'get'
    });


    useEffect(() => {
        window.scrollTo(0,0);
        if(apiData.results && apiData.results.length != 0) {
            setArticles(apiData.results);
            setTotalPage(apiData.total / size);
        }
    }, [apiData, page, size])

    if(apiData.length == 0) {
        return <></>;
    }

    const paging = [];

    for(var i=0; i<totalPage; i++) {
        var pageNumber=i+1;
        paging.push(
            <Link to={'/news?page='+pageNumber+'&size='+size} className={'pageNumber' + ((pageNumber == page) ? " currentPage" : "")}>
                {pageNumber}
            </Link>
        );
    }

    return (
        <div className="news-main">
            <div className="container">
                <h4>{t('news.summary')}</h4>
                <div className="news-summaries">
                {
                    articles.map((news, index) => {
                        return <NewsSummary news={news} />
                    })
                }
                </div>
                
                <div className="paging">
                    {t('news.page')}<span>{paging}</span>
                </div>

            </div>
        </div>
    )
}

export default NewsMain;

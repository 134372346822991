const languages = [
    {
        languageCode: "vn",
        displayName: "Tiếng Việt"
    },
    {
        languageCode: "en",
        displayName: "English"
    }
]

export default languages;

import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import useFetch from '../../../hooks/useFetch/index';

import './NewsDetails.scss';

const ArticleDetails = () => {

    const { id } = useParams();

    const { apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}articles/findById/${id}`,
        method: 'get'
    })

    useEffect(() => {
        window.scrollTo(0,0);
    },[apiData])


    if(apiData.length === 0) {
        return (
            <></>
        )
    }
    return (
        <div className="article-details wow fadeIn">
            <div className="headers container">
                <div className="articleImage">
                    <img src={process.env.REACT_APP_API_STATIC + '/' + apiData[0].image_path }/>
                </div>
            </div>
            <div className="title container">
                <h3>{apiData[0].title}</h3>
            </div>
            <div className="sub-articles">
                <div className="container">
                {apiData[0].subArticles.map((subArticle, index) => {
                    return (
                        <div className="sub-article wow fadeIn">
                            <div className="content">
                                <p>{subArticle.content}</p>
                            </div>
                            <div className="sub-article-image">
                                <img src={process.env.REACT_APP_API_STATIC + '/' + subArticle.image_path} />
                            </div>
                        </div>
                    )
                })} 
                </div>

                <div className="star-breakline" />
            </div>
        </div>
    )
}

export default ArticleDetails;

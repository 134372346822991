import React, { useState } from 'react';

import ContactDetails from '../../../contact-details/ContactDetails';
import Contact from '../../../contact/Contact';

import './ContactLayout.scss';
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/Footer';

const ContactLayout = () => {

    return (
        <div className="main-page">
        <Navbar/>
        <div className="contact-layout">
            <ContactDetails/>
            <div className="star-breakline revert"/>
            <Contact />
        </div>
        <Footer/>
        </div>
    )
}

export default ContactLayout;

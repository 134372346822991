import React, { useContext } from 'react';

import { CategoryContext } from '../../contexts/CategoryContext';

import './AlbumManagement.scss';

import {BrowserRouter as Router, Route, Link, useHistory} from 'react-router-dom';

import useFetch from '../../hooks/useFetch';

import AlbumEditor from './album-editor/AlbumEditor';
import axios from 'axios';
import { toast } from 'react-toastify';

import generateRandomString from '../../utils/generate-random-string';

const AlbumManagement = () => {

    const history = useHistory();

    const context = useContext(CategoryContext);

    const { apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}albums`,
        method: 'get'
    }); 

    if(apiData.length == 0) {
        return <></>;
    }

    const handleDelete = async (id) => {
        try {
            const response = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_HOST}albums/${id}`,
                withCredentials: true
            });

            if(response.status === 204) {
                toast("Xóa thành công");
                history.push('/admin/albums');
                context.setLatestPostRequest(generateRandomString(8));
            }
        } catch(error) {
            toast(error?.response?.data.message);
        }
    }


    return (
        <Router>
            <div className="sub-navbar sub-navbar-album">
                <h3>Album<Link to={"/admin/albums"} ><i className="fa fa-plus-circle add-icon-style"></i></Link></h3>
                <ul>
                {
                    apiData.map((album,index) => {
                        return (
                            <li>
                            <Link to={'/admin/albums/' + album.id}><p>{(album.title) ? album.title : "Chưa có dữ liệu về ngôn ngữ này"}</p></Link>
                            <a
                            className="delete-article"
                            onClick={() => {if(window.confirm("Bạn có chắc chắn muốn xóa album")) handleDelete(album.id)}}
                            >
                            <i class="fa fa-minus-circle delete-icon-style"></i>
                            </a>

                            </li>

                        )
                    })
                }
                </ul>
            </div>
            <div className="main-editor">
                <Route path="/admin/albums/:id?" component={AlbumEditor}/>
            </div>
        </Router>
    )
}

export default AlbumManagement;

import React, { useContext } from 'react';

import { Redirect } from 'react-router-dom';

import AdminNavbar from '../../../admin-navbar/AdminNavbar';
import CustomerContact from '../../../customer-contact/CustomerContact';
import { CategoryContext } from '../../../../contexts/CategoryContext';

const CustomerContactLayout = () => {
    const context = useContext(CategoryContext);

    if(!context.userContext) {
        return (<Redirect to='/login' />)
    }

    return (
        <>
            <div class="admin-layout">
                <AdminNavbar/>
                <CustomerContact />
            </div>
        </>
    )

}

export default CustomerContactLayout;

import React ,{ useState, useEffect, useContext } from 'react';

import Cookies from 'universal-cookie';

import axios from 'axios';

import { toast } from'react-toastify';

import { CategoryContext } from '../../contexts/CategoryContext';

import { WOW } from 'wowjs';

const useFetch = (request) => {

    const cookies = new Cookies();

    const [apiData, setApiData] = useState([]);
    const [serverError, setServerError] = useState(null);

    const context = useContext(CategoryContext);

    useEffect(() => {
        if(!request.url) {
            return { apiData, serverError };
        }

        context.setIsLoading(true);
        const fetchData = async () => {
            try {
                const resp = await axios({
                    url: request.url,
                    method: request.method,
                    headers: {
                        'languageCode': context.languageCode,
                        'Access-Control-Allow-Origin' : '*'
                    },
                    body: request.body,
                    withCredentials: true,
                });
                const data = await resp?.data;
                setApiData(data);
                context.setIsLoading(false);
            } catch (error) {
                toast(error?.response?.data.message || 'unknow error');
                context.setIsLoading(false);
            }
        };

        fetchData();
    }, [request.url, context.languageCode,context.latestPostRequest]);


    return { apiData, serverError };
};

export default useFetch;

import React from 'react';

import './IntroductionManagement.scss';

import {BrowserRouter as Router, Route, Link } from 'react-router-dom';

import useFetch from '../../hooks/useFetch/index';

import IntroductionEditor from './introduction-editor/IntroductionEditor';

const IntroductionManagement = () => {

    return (
        <>
            <div className="main-editor">
                <IntroductionEditor/>
            </div>
        </>
    )
}

export default IntroductionManagement;

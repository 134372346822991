import React,{ useContext, useRef, useState } from 'react';
import { CategoryContext } from '../../contexts/CategoryContext';
import axios from 'axios';
import { toast } from 'react-toastify';

const ImageUploadHandler = (props) => {

    const context = useContext(CategoryContext);

    const imageRef = useRef(null);

    const [selectedFile, setSelectedFile] = useState();

    const handleSelectedImageClick = () => {
        imageRef.current.click();
    }


    const uploadFileHandler = async (e) => {

        const handler = async () => {
            context.setIsLoading(true);
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            const response = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}uploads`,
                headers: {
                    jwtToken: context.jwtToken,
                },
                data: formData
            });

            const data = await response?.data;
            props.setInputField({...props.inputField, ['imagePath']: data.fileName});
            context.setIsLoading(false);
            toast("Thêm ảnh thành công");
        }

        handler();
    }

    return (
        <div className="image-div" onClick={handleSelectedImageClick}>
        { props.inputField.imagePath ? (
            <div className="display-image" >
            <img src={process.env.REACT_APP_API_STATIC + props.inputField.imagePath} label="image"/>
            </div>
        ) : (<div className="unknown-image">Vui lòng chọn ảnh bìa</div>)}
        <input
        type="file"
        name="image"
        id="image"
        value={selectedFile}
        onChange={uploadFileHandler}
        ref={imageRef} hidden/>
        </div> 

    ) 
}

export default ImageUploadHandler;

import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Login from '../../login/Login';
import CategoryManagement from '../../category-manegement/CategoryManagement';

import AdminNavbar from '../../admin-navbar/AdminNavbar';
import Cookies from 'universal-cookie';

import { CategoryContext } from '../../../contexts/CategoryContext';

import './AdminLayout.scss';

const AdminLayout = () => {
    const cookies = new Cookies();
    const context = useContext(CategoryContext)

    if(!context.userContext) {
        return (
            <Login />
        ); 
    }

    return (
        <div className="admin-layout">
            <AdminNavbar/>
        </div>
    )
}

export default AdminLayout;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { CategoryContext } from '../../../../contexts/CategoryContext';
import axios from 'axios';
import { toast } from 'react-toastify';

import './AlbumImage.scss';

const AlbumImage = (props) => {

    const context = useContext(CategoryContext);

    const imageRef = useRef(null);

    const [selectedFile, setSelectedFile] = useState();

    useEffect(() => {
        console.log(props.index);
    },[])


    if(!props.images) {
        return <></>
    }

    
    const handleSelectedImageClick = () => {
        imageRef.current.click();
    }


    const uploadFileHandler = async (e) => {

        const handler = async () => {
            context.setIsLoading(true);
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            const response = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}uploads`,
                headers: {
                    jwtToken: context.jwtToken,
                },
                data: formData
            });

            const data = await response?.data;
            props.handleImageChange(props.index, data.fileName);
            context.setIsLoading(false);
            toast("Thêm ảnh thành công");
        }

        handler();
    }

    return (
        <>
        <div className="image-div" >
            <a onClick={() => {if(window.confirm('Delete?' )) props.handleDeleteImage(props.index)}}>
                <i class="fa fa-minus-circle delete-icon-style"></i>
            </a>
            <div className="display-image" onClick={handleSelectedImageClick}>
        {(props.images[props.index].length > 0) ? (
            <img src={process.env.REACT_APP_API_STATIC + props.images[props.index]}/>
            ) : (<div className="unknown-image">Vui lòng chọn ảnh bìa</div>)
            }
                <input 
                type="file"
                name="image"
                id="image"
                value={selectedFile}
                onChange={uploadFileHandler}
                ref={imageRef} hidden/>
            </div>
        </div>
        </>
    )

}

export default AlbumImage;

import React, { useContext } from 'react';

import CategoryManagement from '../../../category-manegement/CategoryManagement';
import AdminNavbar from '../../../admin-navbar/AdminNavbar';
import Footer from '../../../footer/Footer';

import { Redirect } from 'react-router-dom';

import { CategoryContext } from '../../../../contexts/CategoryContext';

const CategoryManagementLayout = () => {

    const context = useContext(CategoryContext);

    if(!context.userContext) {
        return (<Redirect to='/login' />)
    }

    return (
        <>
            <div class="admin-layout">
                <AdminNavbar/>
                <CategoryManagement/>
            </div>
        </>
    )
}

export default CategoryManagementLayout;

import React, { useState, useEffect, useContext, useRef } from 'react';

import useFetch from '../../../hooks/useFetch/index';

import { useParams } from 'react-router-dom';

import { CategoryContext } from '../../../contexts/CategoryContext';

import axios from 'axios';

import './CategoryEditor.scss';

import ImageUploadHandler from '../../image-upload-handler/ImageUploadHandler';

import generateRandomString from '../../../utils/generate-random-string';

import { toast } from 'react-toastify';

const CategoryEditor = () => {
    const { id } = useParams();

    const { apiData } = useFetch({ 
        url: id ? `${process.env.REACT_APP_API_HOST}categories/${id}/services` : '',
        method: 'get'
    });

    const context = useContext(CategoryContext);

    const [inputField, setInputField] = useState({
        title: '',
        description: '',
        imagePath: ''
    });

    useEffect(() => {
        if(!id) {
            setInputField({
                title: '',
                description: '',
                imagePath: ''
            })
        }
        else if(!apiData.length == 0) {
            setInputField({
                title: apiData[0].title,
                description: apiData[0].description,
                imagePath: apiData[0].image_path
            })
        }
    },[apiData,context.languageCode, id])

    const submitHandler = async () => {
        try {
            const resp = await axios({
                method: id ? 'put' : 'post',
                url: `${process.env.REACT_APP_API_HOST}categories${ id ? ('/' + id) : ''}`,
                data : inputField,
                withCredentials: true
            });
            
            if(resp.status === 200) {
                toast("Cập nhật thành công");
                context.setLatestPostRequest(generateRandomString(8));
            } else {
                toast("failed");
            }
        } catch (error) {
            toast(error?.response?.data.message || 'unknow-error')
        }
    }

    const inputsHandle = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    if( apiData.length == 0) {
        return <></>
    }

    return (
        <div className="category-editor">
        <h3>{ id ? 'Chỉnh sửa danh mục' : 'Thêm mới danh mục' }</h3>
                <p>Tiêu đề</p>
                <input
                type="text"
                name="title"
                onChange = {inputsHandle}
                placeholder = "title"
                value = {inputField.title} />
                <p>Nội dung</p>
                <textarea
                rows="30"
                type="text"
                name="description"
                onChange = {inputsHandle}
                placeholder = "title"
                value = {inputField.description} />
                <p>Ảnh nền</p>
                <ImageUploadHandler inputField={inputField} setInputField={setInputField} />
            <button className="btn-type-1" onClick={submitHandler}>{id ? 'Update' : 'Create'}</button>
        </div>
    
    )
}

export default CategoryEditor;

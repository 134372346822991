import React, { useContext, useEffect, useRef, useState } from 'react';

import './SubIntroductionEditor.scss';

import axios from 'axios';

import { CategoryContext } from '../../../contexts/CategoryContext';

import ImageUploadHandler from '../../image-upload-handler/ImageUploadHandler';

import { toast } from 'react-toastify';

const SubIntroductionEditor = (props) => {

    const context = useContext(CategoryContext);

    const [subIntroduction, setSubIntroduction] = useState({
        id: '',
        content: '',
        imagePath: ''
    });

    useEffect(() => {
        props.handleSubIntroductionInput(subIntroduction, props.index);
    },[subIntroduction.imagePath])

    useEffect(() => {
        setSubIntroduction({
            id: props.subIntroduction.id,
            imagePath: props.subIntroduction.imagePath,
            content: props.subIntroduction.content,
        });
        console.log(subIntroduction);
        console.log(props.subIntroduction);
    }, [props.subIntroduction])

    useEffect(() => {
        props.handleSubIntroductionInput(subIntroduction, props.index);
    },[subIntroduction])

    const inputsHandle = (e) => {
        setSubIntroduction({...subIntroduction, [e.target.name]: e.target.value});
        props.handleSubIntroductionInput(subIntroduction, props.index);
    }

    return(
        <>
        <ImageUploadHandler inputField={subIntroduction} setInputField={setSubIntroduction} />
        <div className="input-div">
        <textarea
        name="content"
        value={subIntroduction.content}
        onChange = {inputsHandle}
        />
        <a onClick={() => {if(window.confirm('Delete?' )) props.handleDeleteSubIntroduction(subIntroduction, props.index)}}>
            <i class="fa fa-minus-circle delete-icon-style"></i>
        </a>
        </div>
        
        </>
    )
}

export default SubIntroductionEditor;

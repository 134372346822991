import React, { useState } from 'react';

import NewsDetails from '../../news/news-details/NewsDetails';
import News from '../../news/news-sub/News';
import Contact from '../../contact/Contact';

import './ArticleDetails.scss';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';

const ArticleDetailLayout = () => {

    return (
        <div className="main-page">
        <Navbar/>
        <div className="article-layout">
            <NewsDetails/>
            <News/>  
            <Contact/>
        </div>
        <Footer/>
        </div>
    )
}

export default ArticleDetailLayout;

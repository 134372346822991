import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Language from '../navbar/language/Language';
import './AdminNavbar.scss';

import { CategoryContext } from '../../contexts/CategoryContext';

const AdminNavbar = () => {

    const context = useContext(CategoryContext);

    const logoutHandle = () => {
        context.setUserContext(null);
    }
    
    return (
        <div className="navbar-admin">
            <div className="logo">
                <Link to="/"><img src={process.env.PUBLIC_URL + "/images/logo.svg"} /></Link>
            </div>

            <div className="username-box">
                <h5>Tên người dùng</h5>
                <p className="username">{context.userContext.username}</p>
                <p onClick={logoutHandle}>Thoát</p>
            </div>

            <Language/>

            <h5>Danh sách tính năng:</h5>
            <Link to="/admin/categories">Danh mục</Link>
            <Link to="/admin/articles">Bài viết</Link>
            {
                (context.userContext.role == "1") ? 
                <Link to="/admin/accounts">Người dùng</Link>
                : ""
            }
            <Link to="/admin/mailbox">Danh sách tư vấn</Link>
            <Link to="/admin/introduction">Giới thiệu bác sỹ</Link>
            <Link to="/admin/contact">Liên hệ</Link>
            <Link to="/admin/albums">Album</Link>
        </div>
    )

}

export default AdminNavbar;

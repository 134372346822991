import React, { useContext, useEffect, useRef, useState } from 'react';

import './SubServiceEditor.scss';

import axios from 'axios';

import ImageUploadHandler from '../../../image-upload-handler/ImageUploadHandler';

import { CategoryContext } from '../../../../contexts/CategoryContext';

const SubServiceEditor = (props) => {

    const context = useContext(CategoryContext);

    const imageRef = useRef(null);

    const [ selectedFile, setSelectedFile ] = useState();

    const [subService, setSubService] = useState({
        id: '',
        content: '',
        imagePath: ''
    });

    useEffect(() => {
        props.handleSubServiceInput(subService, props.index);
    },[subService.imagePath])

    useEffect(() => {
        setSubService({
            id: props.subService.id,
            imagePath: props.subService.imagePath,
            content: props.subService.content,
        });
    }, [props.subService])

    useEffect(() => {
        props.handleSubServiceInput(subService, props.index);
    }, [subService])


    const inputsHandle = (e) => {
        setSubService({...subService, [e.target.name]: e.target.value});
    }

    const uploadFileHandler = async (e) => {
        context.setIsLoading(true);
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_HOST}uploads`,
            headers: {
                jwtToken: context.jwtToken,
            },
            data: formData
        });

        const data = await response?.data;
        setSubService({...subService, ['imagePath']: data.fileName});
        context.setIsLoading(false);
    }

    const handleSelectedImageClick = () => {
        imageRef.current.click();
    }

    return(
        <>
        <ImageUploadHandler inputField={subService} setInputField={setSubService}/>

                <div className="input-div">
        <textarea
        name="content"
        value={subService.content}
        onChange = {inputsHandle}
        />
        <a onClick={() => {if(window.confirm('Delete?' )) props.handleDeleteSubService(subService, props.index)}}>
            <i class="fa fa-minus-circle delete-icon-style"></i>
        </a>
        </div>
        
        </>
    )

}

export default SubServiceEditor;

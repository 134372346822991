import React, { useContext, useRef, useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, useHistory } from 'react-router-dom';

import './CategoryManagement.scss';

import useFetch from '../../hooks/useFetch/index';
import { Link, useParams } from 'react-router-dom';

import { CategoryContext } from '../../contexts/CategoryContext';

import axios from 'axios';

import './CategoryManagement.scss';

import { toast } from 'react-toastify';

import ServiceManagement from '../service-management/ServiceManagement';
import CategoryEditor from './category-editor/CategoryEditor';
import ServiceEditor from './service-editor/ServiceEditor';

import ServiceList from './service-list/ServiceList';

import generateRandomString from '../../utils/generate-random-string';

import { Sortable, Swap } from 'sortablejs/modular/sortable.core.esm.js';


Sortable.mount(new Swap())

const CategoryManagement = () => {

    
    const context = useContext(CategoryContext);

    let history = useHistory();

    const [ categories, setCategories ] = useState([]);

    const { apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}categories/services`,
        method: 'get'
    });


    useEffect(() => {
        if(apiData.length !== 0) {
            setCategories(apiData);
        }
    },[apiData, context.getLatestPostRequest])

    if(apiData.length == 0) {
        return <></>;
    }

    const handleDeleteCategory = async (id) => {
        try {
            const response = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_HOST}categories/${id}`
            })
            if(response.status === 200) {
                history.push('/admin/categories');
                toast("Xóa thành công");
                context.setLatestPostRequest(generateRandomString(8));
            }
        } catch(error) {
            toast(error.response.data.mesage);
        }
    }

    const handleDeleteService = async (id) => {
        try {
            const response = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_HOST}services/${id}`
            })

            if(response.status === 200) {
                history.push('/admin/categories');
                toast("Xóa thành công");
                context.setLatestPostRequest(generateRandomString(8));
            }
        } catch(error) {
            toast(error?.response?.data.message);
        }

    }

    const el = document.querySelector("#categories");
    if(el) {
        const sortable = new Sortable.create(el, {
            group: "categories",
            swap: true,
            filter: ".service",
            animation: 150,
            ghostClass: 'sortable-ghost',
            chosenClass: 'sortable-chosen',
            dragClass: 'sortable-drag',

            onSort: async function(evt) {
                const input = {
                    fromIndex: evt.oldIndex,
                    toIndex: evt.newIndex
                }

                try {
                    const response = await axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_HOST}categories/changeOrder`,
                        data: input,
                        withCredentials: true
                    })

                    if(response.status === 200) {
                        toast("Cập nhật thành công");
                        context.setLatestPostRequest(generateRandomString(8));
                    }
                } catch(error) {
                    toast(error?.response?.data.message || "unknown error");
                }

            }
        })

    }

    return (
        <>
            <Router>
            <div className="sub-navbar sub-navbar-category">
            <h3>Danh mục <Link to={"/admin/categories"}><i className="fa fa-plus-circle add-icon-style"></i></Link></h3>
            <ul id="categories">
                {
                    categories.map((category, index) => {
                        return (
                            <li key={category.id} className="title">
                                <p className="title">
                                    <Link to={'/admin/categories/' + category.id}>{category.title || "Chưa có dữ liệu"}</Link>
                                    <a onClick={() => {if(window.confirm("Bạn có chắc chắn muốn xóa?")) handleDeleteCategory(category.id)}} value={category.id}>
                                        <i class="fa fa-minus-circle delete-icon-style"></i>
                                    </a>
                                    <Link to={'/admin/categories/' + category.id + '/services'}>
                                        <i className="fa fa-plus-circle add-icon-style"></i>
                                    </Link>
                                    
                                </p>
                                <ServiceList category={category} handleDeleteService={handleDeleteService}/>
                          </li>
                        )
                    })
                }
            </ul>
            </div>
            <div className="main-editor">
                <Route path="/admin/categories/:id?" exact component={CategoryEditor}/>
                <Route path="/admin/categories/:categoryId/services/:serviceId?" component={ServiceEditor}/>

            </div>
            
            </Router>
        </>
    )
}


export default CategoryManagement;

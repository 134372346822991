import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom'; import useFetch from '../../../hooks/useFetch';

import { CategoryContext } from '../../../contexts/CategoryContext';

import SubServiceEditor from './sub-service-editor/SubServiceEditor';

import ImageUploadHandler from '../../image-upload-handler/ImageUploadHandler';

import './ServiceEditor.scss';

import axios from 'axios';

import { toast } from 'react-toastify';

import generateRandomString from '../../../utils/generate-random-string';

const ServiceEditor = () => {

    const { categoryId, serviceId } = useParams();

    const { apiData, isLoading } = useFetch({
        url: serviceId ? `${process.env.REACT_APP_API_HOST}services/${serviceId}` : '',
        method: 'get'
    });

    const [ subServices, setSubServices] = useState([]);

    const context = useContext(CategoryContext);

    const [ inputField, setInputField ] = useState({
        title: '',
        description: '',
        quote: '',
        subTitle: '',
        subDescription: '',
        imagePath: ''
    });

    useEffect(() => {
        if(!serviceId) {
            setInputField({
                title: '',
                description: '',
                quote: '',
                subTitle: '',
                longDescription: '',
                imagePath: ''
            })
            setSubServices([]);
        } else if(!apiData.length == 0) {
            setInputField({
                title: apiData[0].title || '',
                description: apiData[0].description || '',
                quote: apiData[0].quote || '',
                subTitle: apiData[0].sub_title || '',
                longDescription: apiData[0].long_description || '',
                imagePath: apiData[0].image_path || '',
                subServices: []
            })
            if(apiData[0].subServices) {
                var subServices = [];
                apiData[0].subServices.map((subService, index) => {
                    var input = {
                        id: subService.id,
                        content: subService.content || "",
                        imagePath: subService.image_path || "null"
                    };
                    subServices.push(input);
                });
                setSubServices(subServices);
            }
        }
    },[apiData,context.languageCode, serviceId, categoryId])

    const inputsHandle = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubServiceInput = (subService, index) => {
        subServices[index] = subService;
    }

    const handleSubmit = async () => {
        try {
            const response = await axios({
                method: serviceId ? 'put' : 'post',
                url: `${process.env.REACT_APP_API_HOST}categories/${categoryId}/services${serviceId ? ('/' +serviceId) : ''}`,
                data: {...inputField, ['subServices']: subServices},
                withCredentials: true
            })

            const data = await response.data;
            if(response.status === 200) {
                toast("Cập nhật thành công");
                context.setLatestPostRequest(generateRandomString(8));
            } else {
                toast("failed");
            }
        } catch(error) {
            toast(error.response.data.message || 'unknown-error')
        }
    }

    const addSubServiceHandler = async () => {
        setSubServices(oldSubService => [...oldSubService, {id: '', content: '', imagePath: ''}]);
    }

    const handleDeleteSubSurvice = async (subService, index) => {
        if(!subService.id || subService.id.length === 0) {
            const newSubServices = [...subServices];
            newSubServices.splice(index,1);
            setSubServices(newSubServices);
            toast("Xóa thành công");
        } else {
            try {
                const response = await axios({
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_HOST}sub_services/${subService.id}`,
                    headers: {
                        jwtToken: context.jwtToken
                    }
                })
                if(response.status === 200) {
                    toast("Xóa thành công");
                    context.setLatestPostRequest(generateRandomString(8));
                }
            } catch (error) {
                toast(error?.response?.data.message);
            }
        }
    }

    return (
        <>
        <div className="service-editor">
            <h3>{ serviceId ? "Cập nhập dịch vụ" : "Thêm mới dịch vụ"}</h3>
            <p>Tiêu đề</p>
            <input
            type="text"
            name="title"
            onChange = {inputsHandle}
            placeholder = "Tiêu đề"
            value = {inputField.title}/>
            <p>Mô tả tiêu đề</p>
            <textarea
            name="description"
            onChange = {inputsHandle}
            placeholder = "Mô tả tiêu đề"
            value = {inputField.description}/>
            <p>Quote</p>
            <input
            type="text"
            name="quote"
            onChange = {inputsHandle}
            placeholder = "Quote"
            value = {inputField.quote}/>
            <p>Tiêu đề phụ</p>
            <input
            type="text"
            name="subTitle"
            onChange = {inputsHandle}
            placeholder = "Tiêu đề phụ"
            value = {inputField.subTitle}/>
            <p>Mô tả phụ</p>
            <textarea
            name="longDescription"
            onChange = {inputsHandle}
            placeholder = "Mô tả phụ"
            value = {inputField.longDescription}/>

            <p>Ảnh nền</p>
        <ImageUploadHandler inputField={inputField} setInputField={setInputField} />
        </div>

        <div className="sub-service-editor">
            <h3> Chi tiết dịch vụ </h3>
            {subServices.map((subService, index) => {
                return (
                    <>
                        <SubServiceEditor 
                        handleSubServiceInput={handleSubServiceInput}
                        subService={subService}
                        index={index}
                        handleDeleteSubService={handleDeleteSubSurvice}/>
                    </>
                )
            })}
        <button className="btn-type-2 addService" onClick={addSubServiceHandler}>
            <i className="fa fa-plus"></i>
        </button>

        </div>

        <button className="btn-type-1 submit-btn" onClick={handleSubmit}> {(serviceId ? "Cập nhật" : "Thêm mới")  + " dịch vụ"}</button>

        </>
    )
}

export default ServiceEditor;

import './LoadingComponent.scss'

import { CategoryContext } from '../../contexts/CategoryContext';
import { useContext } from 'react';

const LoadingComponent = () => {

    const context = useContext(CategoryContext);

    return (
        <div class={'lds-default' + (context.isLoading ? " isLoading" : "")}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    )
}

export default LoadingComponent;

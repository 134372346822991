import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import { toast } from'react-toastify';

import { useParams } from 'react-router-dom';

import useFetch from '../../../hooks/useFetch/index';

import { CategoryContext } from '../../../contexts/CategoryContext';

import CustomInput from '../../custom-input/CustomInput';

import ImageUploadHandler from '../../image-upload-handler/ImageUploadHandler';

import AlbumImage from './album-image/AlbumImage';

import './AlbumEditor.scss';

import generateRandomString from '../../../utils/generate-random-string';

import { useHistory } from 'react-router-dom';

const AlbumEditor = () => {

    const history = useHistory();

    const { id } = useParams();

    const context = useContext(CategoryContext);

    const [ inputField, setInputField ] = useState({
        title: '',
        description: '',
        images: []
    });
    
    const [images, setImages] = useState([]);

    const { apiData } = useFetch({
        url: id ? `${process.env.REACT_APP_API_HOST}albums/${id}`: '',
        method: 'get'
    });

    useEffect(() => {
        if(!id) {
            var emptyInput = {
                title: '',
                description: '',
            };
            setInputField(emptyInput);
            setImages([]);
        } else if(!apiData.length == 0) {
            setInputField({
                //images: apiData[0].images || [],
                title: apiData[0].title || '',
                description: apiData[0].description || ''
            })
            setImages(apiData[0].images || []);
        }
    }, [apiData, context.languageCode, id])

    const inputsHandle = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    //const handleSelectedImageClick = () => {
        //imageRef.current.click();
    //}

    const handleImageChange = (index, imagePath) => {
        var newImages = images;
        newImages[index] = imagePath;
        setImages(newImages);
    }

    const handleSubmit = async () => {
        try {
            const response = await axios({
                method: id ? 'put' : 'post',
                url: `${process.env.REACT_APP_API_HOST}albums/${id ? id : ''}`,
                data: {...inputField, ['images']: images},
                withCredentials: true
            })
            
            if(response.status === 200) {
                toast("Cập nhật thành công");
                context.setLatestPostRequest(generateRandomString(8));
            } else {
                toast("failed");
            }
        } catch (error) {
            toast(error?.response?.data.message);
        }
    }
    
    const handleAddImage = async () => {
        setImages(oldImages => [...oldImages, '']);
    }

    const handleDeleteImage = async (index) => {

        const newImages = [...images];
        newImages.splice(index,1);
        setImages(newImages);
    }

    const handleDelete = async () => {
        try {
            const response = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_HOST}albums/${id}`,
                withCredentials: true
            });

            if(response.status === 204) {
                toast("Xóa thành công");
                history.push('/articles');
                context.setLatestPostRequest(generateRandomString(8));
            }
        } catch(error) {
            toast(error?.response?.data.message);
        }
    }

    if(apiData.length == 0 && id) {
        return <></>;
    }

    return (
        <>
        <div className="album-editor">
        <div className="header">{id 
            ? (<><h3>Chỉnh sửa album</h3> 
            <button
            className="delete-article"
            onClick={() => {if(window.confirm("Bạn có chắc chắn muốn xóa ảnh")) handleDelete()}}
            >
                <i className="fa fa-minus"></i>
                </button></>)
            : <h3>Thêm mới ảnh</h3>
        }</div>
        <p>Tiêu đề</p>
        <input
        type="text"
        name="title"
        onChange= {inputsHandle}
        placeholder = "Tiêu đề"
        value = {inputField.title} />
        <p>Mô tả</p>
        <textarea
        name="description"
        rows="10"
        onChange= {inputsHandle}
        placeholder = "Mô tả"
        value = {inputField.description} />

        {
            images.map((image,index) => {
                return (
                        <AlbumImage images={images} 
                        handleImageChange={handleImageChange}
                        handleDeleteImage={handleDeleteImage}
                        index={index}/>
                )
            })
        }

        <button className="btn-type-2 addSubArticle" onClick={handleAddImage}>
            <i className="fa fa-plus"></i>
        </button>
        </div>

        <button className="btn-type-1" onClick={handleSubmit}> {id ? "Cập nhật" : "Thêm mới"}</button>
        </>
    )
}

export default AlbumEditor;

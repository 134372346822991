import React from 'react';

import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import Contact from '../../contact/Contact';
import News from '../../news/news-sub/News';

import Album from '../../album/Album';

import "./AlbumLayout.scss";

const AlbumLayout = () => {

    return (
        <div className="main-page">
        <Navbar/>
        <div className="album-layout">
            <Album/>
            <News/>
            <Contact />
        </div>
        <Footer/>
        </div>
    )
}

export default AlbumLayout;

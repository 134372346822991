import React, { useState } from 'react';

import useFetch from '../../hooks/useFetch/index';

import { Link } from 'react-router-dom';

import './CustomerContact.scss';

import dateFormat from 'dateformat';

const CustomerContact = () => {

    const { apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}customer_contacts`,
        method: 'get'
    });

    const [ contactSelecting, setContactSelecting ] = useState();

    if(apiData.length == 0) {
        return <></>
    }

    return (
        <>
        <div className="sub-navbar sub-navbar-mailbox">
            <h3>Hòm thư</h3>
            {
                apiData.map((customerContact, index) => {
                    return (
                        <p className="customer" onClick={() => {setContactSelecting(customerContact)}}>{customerContact.full_name}
                            <span>{dateFormat(customerContact.created_at, "dd/mm/yyyy")}</span>
                        </p>
                    )
                })
            }
        </div>
        <div className="main-customer-contact">
            {contactSelecting ? (
                <>
                    <h3> Thông tin người liên hệ</h3>
                    <p>Họ và tên</p>
                    <p>{contactSelecting.full_name}</p>
                    <p>Số điện thoại</p>
                    <p>{contactSelecting.phone_number}</p>
                    <p>Email</p>
                    <p>{contactSelecting.email}</p>
                </>
            ) : (
                <div> unselected</div>
            )}
        </div>
        </>
    )
}

export default CustomerContact;

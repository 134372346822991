import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';

import useFetch from '../../../hooks/useFetch/index';

import { CategoryContext } from '../../../contexts/CategoryContext';

import SubIntroductionEditor from '../sub-introduction-editor/SubIntroductionEditor';

import './IntroductionEditor.scss';

import ImageUploadHandler from '../../image-upload-handler/ImageUploadHandler';

import { toast } from 'react-toastify';

import generateRandomString from '../../../utils/generate-random-string';


const IntroductionEditor = () => {

    const context = useContext(CategoryContext);

    const [ inputField, setInputField ] = useState({
        title: '',
        description: '',
        imagePath: ''
    });

    const [ subIntroductions, setSubIntroductions ] = useState([]);

    const { apiData } = useFetch({
        url:`${process.env.REACT_APP_API_HOST}static_data/introduction`,
        method: 'get'
    });

    useEffect(() => {
        if(!apiData.length == 0) {
            setInputField({
                imagePath: apiData[0].image_path || '',
                title: apiData[0].title || '',
                description: apiData[0].description || ''
            })
            
            if(apiData[0].subArticles) {
                var subIntroductions = [];
                apiData[0].subArticles.map((subIntroduction, index) => {
                    var input = {
                        id: subIntroduction.id,
                        content: "",
                        imagePath: subIntroduction.image_path || "null"
                    };
                    input.content = subIntroduction.content || '';
                    subIntroductions.push(input);
                })
                setSubIntroductions(subIntroductions);
            }
        }
    }, [apiData, context.languageCode])

    const inputsHandle = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubmit = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}static_data/introduction`,
                headers: {
                    languageCode: context.languageCode,
                    jwtToken: context.jwtToken
                },
                data: {...inputField, ['subIntroductions']: subIntroductions}
            })

            if(response.status === 200) {
                toast("Cập nhật thành công");
                context.setLatestPostRequest(generateRandomString(8));
            } else {
                toast('failed');
            }
        } catch (error) {
            toast(error?.response?.data.message);
        }

    }

   const addSubIntroductionHandler = async () => {
        setSubIntroductions(oldSubIntroduction => [...oldSubIntroduction, {id: '', content: '', imagePath: ''}]);
    }


    const handleSubIntroductionInput = (subIntroduction, index) => {
        subIntroductions[index] = subIntroduction;
    }

    const handleDeleteSubIntroduction = async (subIntroduction, index) => {
        if(!subIntroduction.id || subIntroduction.id.length === 0) {
            const newSubIntroductions = [...subIntroductions];
            newSubIntroductions.splice(index,1);
            setSubIntroductions(newSubIntroductions);
            toast("Xóa thành công");
        } else {
            const response = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_HOST}sub_articles/${subIntroduction.id}`,
                headers: {
                    jwtToken: context.jwtToken
                }
            })

            if(response.status === 200) {
                toast("Xóa thành công");
                context.setLatestPostRequest(generateRandomString(8));
            } else {
                toast("failed");
            }
        }
    }


    return (
        <>
        <div className="introduction-editor">
        <h3>Chỉnh sửa phần giới thiệu</h3>
        <h5>Tiêu đề</h5>
        <input
        type="text"
        name="title"
        onChange= {inputsHandle}
        placeholder = "Tiêu đề"
        value = {inputField.title} />
        <h5>Mô tả</h5>
        <textarea
        name="description"
        rows="10"
        onChange= {inputsHandle}
        placeholder = "Mô tả"
        value = {inputField.description} />
        <p>Ảnh nền</p>
        <ImageUploadHandler inputField={inputField} setInputField={setInputField}/>
        </div>
        
        <div className="sub-introduction-editor">
            <h3> Chi tiết giới thiệu</h3>
            {subIntroductions.map((subIntroduction, index) => {
                console.log(subIntroduction)
                return (
                    <>
                        <SubIntroductionEditor 
                        handleSubIntroductionInput={handleSubIntroductionInput}
                        subIntroduction={subIntroduction}
                        index={index}
                        handleDeleteSubIntroduction={handleDeleteSubIntroduction}/>
                    </>
                )
            })}
        <button className="btn-type-2" onClick={addSubIntroductionHandler}>
            <i className="fa fa-plus"></i>
        </button>

        </div>

        <button className="btn-type-1" onClick={handleSubmit}>Cập nhật</button>
        </>
    )
}

export default IntroductionEditor;

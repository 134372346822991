import React, { useState } from 'react';

import CategoryDetails from '../../category-details/CategoryDetails.js';
import Introduction from '../../introduction/Introduction.js';
import News from '../../news/news-sub/News.js';
import Contact from '../../contact/Contact.js';

import { useParams } from 'react-router-dom';

import './CategoryDetailLayout.scss';

import { ServiceContext } from '../../../contexts/ServiceContext';
import { CategoryContext } from '../../../contexts/CategoryContext';

import useFetch from '../../../hooks/useFetch/index';
import Navbar from '../../navbar/Navbar.js';
import Footer from '../../footer/Footer.js';

const CategoryDetailLayout = () => {
    const { id } = useParams();

    const [newsSize, setNewsSize] = useState(4);
    const { apiData, isLoading } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}categories/${id}/services`,
        method: 'get'
    });

    if(isLoading || apiData.length == 0) {
        return (<div>loading</div>);
    }

    return (
        <div className="main-page">
        <ServiceContext.Provider value={{apiData}}>
        <Navbar/>
        <div className="category-layout">
            <CategoryDetails/>
            <Introduction/>
            <News/>
            <div className="star-breakline revert" size={newsSize} />
            <Contact/>
        </div>
        <Footer/>

        </ServiceContext.Provider>
        </div>
    )
}

export default CategoryDetailLayout;

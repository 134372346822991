import './Language.scss';
import React, { useContext, useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';

import dataLanguages from '../../../data/language.js';

import { CategoryContext } from '../../../contexts/CategoryContext';

import { useTranslation } from 'react-i18next';

const Language = () => {
    
    const [cookie, setCookie] = useCookies(['language']);

    const context = useContext(CategoryContext);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(!cookie.languageCode) {
            setCookie('languageCode',"vn", {path: '/'})
            context.setLanguageCode("vn");
        }

        i18n.changeLanguage(context.languageCode);
    },[])

    const changeContextLanguage= (languageCode) => {
        context.setLanguageCode(languageCode);
        setCookie('languageCode',languageCode, { path: '/'});
        i18n.changeLanguage(languageCode);
    }

    return (
        <ul className="languages">
            {
                dataLanguages.map((language,index) => {
                    return (
                        <li 
                        onClick={() => changeContextLanguage(language.languageCode)}
                        className={context.languageCode === language.languageCode ? "isSelected" : ""}>
                        {(context.width >  768) ? language.displayName : language.languageCode}
                        </li>
                    )
                })
            } 
        </ul>	
    )
}

export default Language;

import React from 'react';

import {BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './AccountManagement.scss';
import AccountEditor from '../account-management/account-editor/AccountEditor';

import useFetch from '../../hooks/useFetch/index';

const AccountManagement = () => {

    const { apiData, isLoading } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}accounts`,
        method: 'get',
    });

    if(apiData.length == 0) {
        return <></>
    }

    return (
        <>
            <Router>
                <div className="sub-navbar sub-navbar-account">
                    <h3>Tài khoản</h3>
                    <ul>
                    {
                        apiData.map((account, index) => {
                            return <li key={index}>
                                    <Link to={'/admin/accounts/' + account.id}>{account.username}</Link>
                                </li>
                        })
                    }
                    </ul>
                <Link to={'/admin/accounts'} className="add-account">Thêm mới</Link>
                </div>
                <div className="main-editor">
                    <Route path="/admin/accounts/:id?" component={AccountEditor}/>
                </div>
            </Router>
        </>
    )
}

export default AccountManagement;

import React, { useContext, useEffect } from 'react';

import './CategoryDetails.scss';
import category from '../../data/category-detail.js';
import { ServiceContext } from '../../contexts/ServiceContext';
import { Link, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const CategoryDetails = (props) => {

    const history = useHistory();

    const serviceContext = useContext(ServiceContext);

    const { t } = useTranslation();
   
    if(serviceContext.isLoading) {
        return (<div>loading</div>);
    }

    const handleReadmoreService = (service) => {
        history.push('/services/' + service.id);
    }

	return (
		<div className="category-details wow fadeIn">
			<div className="header">
				<div className="container">
					<div className="image">
                        <img src={process.env.REACT_APP_API_STATIC + serviceContext.apiData[0].image_path} />
                        <i className="star-img"></i>
					</div>	
					<div className="content">
						<h4>{serviceContext.apiData[0].title}</h4>
						<p>{serviceContext.apiData[0].description}</p>
					</div>
				</div>
			</div>
			
			<div className="main-content">
				<div className="container">
					{serviceContext.apiData[0].services.map((service,index) => {
						return(
							<div className="service wow fadeIn">
								<div className="service-container">
									<div className="image">
                                        <img src= {process.env.REACT_APP_API_STATIC + service.image_path} />	
									</div>
									<div className="content">
										<div className="content-container">
                                            <h5 onClick={() => {handleReadmoreService(service)}}>{service.title}</h5>
                                            <p>
                                                {(service.description) ? (service.description.substring(0,100) + (service.description.length > 100 ? " ..." : "")) : ""}
                                            </p>
											<div>
                                                <a onClick={() => {handleReadmoreService(service)}} className="btn-type-1">{t('button.readmore')}</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	
	)
}

export default CategoryDetails;

import React, { useContext, useEffect, useState } from 'react';
import './Categories.scss';

import { Link } from 'react-router-dom';

import { CategoryContext } from '../../../contexts/CategoryContext';

import useFetch from '../../../hooks/useFetch/index';

import { useTranslation } from 'react-i18next';

const Categories = () => {

    const context = useContext(CategoryContext);

    const { t , changeLanguage } = useTranslation();

    const [ navbarWidth, setNavbarWidth ] = useState(0);

    const {isLoading, apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}categories`,
        method: 'get'
   });

    if(apiData.length == 0) {
        return <></>;
    }

    return (
        <ul className="categories">
            {
                apiData.map((category, index) => {
                    return (<li><Link
                        to={'/categories/'+category.id}>
                        {category.title || "content is empty"}
                        </Link></li>)
                })
            }
            <li>
                <p></p>
            <Link to="/news">{t('navbar.news')}</Link></li>
            <li><Link to="/introduction">{t('navbar.introduction')}</Link></li>
            <li><Link to="/contact">{t('navbar.contact')}</Link></li>
            <li><Link to="/album">Album</Link></li>
        </ul>
    )
}

export default Categories;

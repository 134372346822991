import React, { useState } from 'react';

import NewsMain from '../../news/news-main/NewsMain'; 
import Contact from '../../contact/Contact';

import './NewsMainLayout.scss';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';

const NewsMainLayout = () => {
    const [size, setSize] = useState(8);

    return (
        <div className="main-page">
        <Navbar/>
        <div className="news-main-layout">
            <NewsMain size={size}/>
            <div className="star-breakline revert"/>
            <Contact />
        </div>
        <Footer/>
        </div>
    )
}

export default NewsMainLayout;

import { BrowserRouter as Router, Route } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import HomeLayout from './components/layout/home/HomeLayout.js';
import CategoryDetailLayout from './components/layout/category-detail/CategoryDetailLayout.js';
import ServiceDetailLayout from './components/layout/service-detail/ServiceDetailLayout';
import AdminLayout from './components/layout/admin/AdminLayout';
import CategoryManagementLayout from './components/layout/admin/category-management/CategoryManagementLayout';
import AccountManagementLayout from "./components/layout/admin/account-management/AccountManagementLayout";
import CustomerContactLayout from "./components/layout/admin/customer-contact/CustomerContactLayout";
import IntroductionManagementLayout from "./components/layout/admin/introduction-management/IntroductionManagementLayout";
import ArticleManagementLayout from "./components/layout/admin/article-management/ArticleManagementLayout";
import ArticleDetailsLayout from "./components/layout/article-details/ArticleDetails";
import NewsMainLayout from "./components/layout/news-main/NewsMainLayout";
import IntroductionLayout from "./components/layout/admin/introduction/IntroductionLayout";
import ContactManagementLayout from "./components/layout/admin/contact-management/ContactManagementLayout";
import ContactLayout from "./components/layout/admin/contact/ContactLayout";
import AlbumLayout from "./components/layout/album/AlbumLayout";
import AlbumManagementLayout from "./components/layout/admin/album-management/AlbumManagementLayout";
import LoadingComponent from "./components/loading-component/LoadingComponent";

import { CategoryContext } from './contexts/CategoryContext';

import { useEffect, useRef } from "react";

import { useCookies } from 'react-cookie';

import React, { useState } from 'react';
import Login from "./components/login/Login";

import { IntlProvider } from 'react-intl';

import './i18next';

import { useTranslation } from 'react-i18next';

import { WOW } from 'wowjs';

import "wowjs/css/libs/animate.css";

import "../node_modules/react-awesome-slider/dist/styles.css";

function App() {

    const wow = new WOW();

    const [isLoading, setIsLoading] = useState(false);

    const [cookie, setCookie] = useCookies(['language']);

    const [languageCode, setLanguageCode] = useState(cookie.languageCode) || "vn";

    const [username, setUsername] = useState(sessionStorage.getItem('username'));

    const [userContext, setUserContext] = useState(cookie.userContext || null);

    const ref = useRef(null);
    
    const width = ref.current ? ref.current.offsetWidth : 0;

    const height = ref.current ? ref.current.offsetHeight : 0;

    const [latestPostRequest, setLatestPostRequest] = useState();

    const { t, i18n } = useTranslation();

    const [animated, setAnimated] = useState(false);

    useEffect(() => {
        wow.init();
        setAnimated(true);
    })

    if(!animated) {
        return <></>
    }

    return ( 
        <>
        <CategoryContext.Provider value={{
                    wow,
                    width, height,
                    userContext, setUserContext,
                    isLoading, setIsLoading,
                    latestPostRequest, setLatestPostRequest,
                    languageCode, setLanguageCode,
                    username, setUsername}}>
        <div className={"app" + (isLoading ? " isLoading" : "")} ref={ref}>
                <Router>
                    <Route path="/" exact component={HomeLayout} />
                    <Route path="/categories/:id" component={CategoryDetailLayout} />
                    <Route path="/services/:id" component={ServiceDetailLayout} />
                    <Route path="/articles/:id" component={ArticleDetailsLayout} />
                    <Route path="/news/" component={NewsMainLayout} />
                    <Route path="/introduction/" component={IntroductionLayout} />
                    <Route path="/album/" component={AlbumLayout} />
                    <Route path="/contact/" component={ContactLayout} />
                    <Route path="/login/" component={Login} />
                    <Route path="/admin" exact component={AdminLayout} />
                    <Route path="/admin/categories/:id?" component={CategoryManagementLayout} />
                    <Route path="/admin/articles/:id?" component={ArticleManagementLayout} />
                    <Route path="/admin/accounts/:id?" component={AccountManagementLayout} />
                    <Route path="/admin/mailbox/:id?" component={CustomerContactLayout} />
                    <Route path="/admin/introduction" component={IntroductionManagementLayout} />
                    <Route path="/admin/contact" component={ContactManagementLayout} />
                    <Route path="/admin/albums/:id?" component={AlbumManagementLayout} />
                </Router>
        </div>
                <ToastContainer />
                <LoadingComponent/>
        </CategoryContext.Provider>
        </>
    )
}

export default App;

import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import { toast } from'react-toastify';

import { useParams } from 'react-router-dom';

import { Editor, EditorState } from 'draft-js';

import useFetch from '../../../hooks/useFetch/index';

import { CategoryContext } from '../../../contexts/CategoryContext';

import SubArticleEditor from './sub-article-editor/SubArticleEditor';

import CustomInput from '../../custom-input/CustomInput';

import ImageUploadHandler from '../../image-upload-handler/ImageUploadHandler';

import './ArticleEditor.scss';

import generateRandomString from '../../../utils/generate-random-string';

import { useHistory } from 'react-router-dom';

const ArticleEditor = () => {

    const history = useHistory();

    const { id } = useParams();

    const context = useContext(CategoryContext);

    const [ inputField, setInputField ] = useState({
        title: '',
        description: '',
        imagePath: ''
    });

    const [ subArticles, setSubArticles ] = useState([]);

    const { apiData } = useFetch({
        url: id ? `${process.env.REACT_APP_API_HOST}articles/findById/${id}`: '',
        method: 'get'
    });

    useEffect(() => {
        if(!id) {
            var emptyInput = {
                title: '',
                description: '',
                imagePath: ''
            };
            setInputField(emptyInput);

            console.log(inputField);
            setSubArticles([]);
        } else if(!apiData.length == 0) {
            setInputField({
                imagePath: apiData[0].image_path || '',
                title: apiData[0].title || '',
                description: apiData[0].description || ''
            })
            
            if(apiData[0].subArticles) {
                var subArticles = [];
                apiData[0].subArticles.map((subArticle, index) => {
                    var input = {
                        id: subArticle.id,
                        content: "",
                        imagePath: subArticle.image_path || "null"
                    };
                    input.content = subArticle.content;
                    subArticles.push(input);
                })
                setSubArticles(subArticles);
            }
        }
    }, [apiData, context.languageCode, id])

    const inputsHandle = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubmit = async () => {
        try {
            const response = await axios({
                method: id ? 'put' : 'post',
                url: `${process.env.REACT_APP_API_HOST}articles/${id ? id : ''}`,
                data: {...inputField, ['subArticles']: subArticles},
                withCredentials: true
            })
            
            if(response.status === 200) {
                toast("Cập nhật thành công");
                context.setLatestPostRequest(generateRandomString(8));
            } else {
                toast("failed");
            }
        } catch (error) {
            toast(error?.response?.data.message);
        }

        setInputField({
            title: '',
            description: '',
            imagePath: ''
        });
    }

    const handleDelete = async () => {
        try {
            const response = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_HOST}articles/${id}`,
                withCredentials: true
            });

            if(response.status === 204) {
                toast("Xóa thành công");
                history.push('/articles');
                context.setLatestPostRequest(generateRandomString(8));
            }
        } catch(error) {
            toast(error?.response?.data.message);
        }
    }

   const addSubArticleHandler = async () => {
        setSubArticles(oldSubArticle => [...oldSubArticle, {id: '', content: '', imagePath: ''}]);
    }


    const handleSubArticleInput = (subArticle, index) => {
        subArticles[index] = subArticle;
    }

    const handleDeleteSubArticle = async (subArticle, index) => {
        if(!subArticle.id || subArticle.id.length === 0) {
            const newSubArticles = [...subArticles];
            newSubArticles.splice(index,1);
            setSubArticles(newSubArticles);
            toast("Xóa thành công");
        } else {
            try {
                const response = await axios({
                    method: 'delete',
                    url: `${process.env.REACT_APP_API_HOST}sub_articles/${subArticle.id}`
                })

                if(response.status === 200) {
                    toast("Xóa thành công");
                    context.setLatestPostRequest(generateRandomString(8));
                } else {
                    toast("failed");
                }
            } catch(error) {
                toast(error?.response?.data.message);
            }
        }
    }


    return (
        <>
        <div className="article-editor">
        <div className="header">{id 
            ? (<><h3>Chỉnh sửa bài viết</h3> 
            <button
            className="delete-article"
            onClick={() => {if(window.confirm("Bạn có chắc chắn muốn xóa bài viết")) handleDelete()}}
            >
                <i className="fa fa-minus"></i>
                </button></>)
            : <h3>Thêm mới bài viết</h3>
        }</div>
        <p>Tiêu đề</p>
        <input
        type="text"
        name="title"
        onChange= {inputsHandle}
        placeholder = "Tiêu đề"
        value = {inputField.title} />
        <p>Mô tả</p>
        <textarea
        name="description"
        rows="10"
        onChange= {inputsHandle}
        placeholder = "Mô tả"
        value = {inputField.description} />

        <p>Ảnh nền</p>
        <ImageUploadHandler inputField={inputField} setInputField={setInputField}/>
        </div>

        <div className="sub-article-editor">
        <h3> Chi tiết bài viết</h3>
        {subArticles.map((subArticle, index) => {
            return (
                <>
                <SubArticleEditor 
                handleSubArticleInput={handleSubArticleInput}
                subArticle={subArticle}
                index={index}
                key={index}
                handleDeleteSubArticle={handleDeleteSubArticle}/>
                </>
            )
        })}
        <button className="btn-type-2 addSubArticle" onClick={addSubArticleHandler}>
            <i className="fa fa-plus"></i>
        </button>
        </div>

        <button className="btn-type-1" onClick={handleSubmit}> {id ? "Cập nhật" : "Thêm mới"}</button>
        </>
    )
}

export default ArticleEditor;

import React, { useContext, useEffect, useRef, useState } from 'react';

import './SubArticleEditor.scss';

import axios from 'axios';

import { CategoryContext } from '../../../../contexts/CategoryContext';

import ImageUploadHandler from '../../../image-upload-handler/ImageUploadHandler';

const SubArticleEditor = (props) => {

    const context = useContext(CategoryContext);

    const [subArticle, setSubArticle] = useState({
        id: '',
        content: '',
        imagePath: ''
    });

    useEffect(() => {
        props.handleSubArticleInput(subArticle, props.index);
    },[subArticle.imagePath])

    useEffect(() => {
        setSubArticle({
            id: props.subArticle.id,
            imagePath: props.subArticle.imagePath,
            content: props.subArticle.content,
        });
        console.log(subArticle);
        console.log(props.subArticle);
    }, [props.subArticle])

    useEffect(() => {
        props.handleSubArticleInput(subArticle, props.index);
    },[subArticle])

    const inputsHandle = (e) => {
        setSubArticle({...subArticle, [e.target.name]: e.target.value});
        props.handleSubArticleInput(subArticle, props.index);
    }
  
    return(
        <>
        <ImageUploadHandler inputField={subArticle} setInputField={setSubArticle}/>
        <div className="input-div">
        <textarea
        name="content"
        value={subArticle.content}
        onChange = {inputsHandle}
        />
        <a onClick={() => {if(window.confirm('Delete?' )) props.handleDeleteSubArticle(subArticle, props.index)}}>
            <i class="fa fa-minus-circle delete-icon-style"></i>
        </a>
        </div>
        
        </>
    )
}

export default SubArticleEditor;

import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import useFetch from '../../hooks/useFetch/index';

import './ContactDetails.scss';

const ContactDetails = () => {

    const { apiData, isLoading } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}static_data/contact`,
        method: 'get'
    })


    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    if(apiData.length === 0) {
        return <></>;
    }
    return (
        <div className="contact-details">
            <div className="headers container">
                <div className="contactImage">
                    <img src={process.env.REACT_APP_API_STATIC + '/' + apiData[0].image_path }/>
                </div>
            </div>
            <div className="title container">
                <h3>{apiData[0].title}</h3>
            </div>
            <div className="sub-contacts">
                <div className="container">
                {apiData[0].subArticles.map((subContact, index) => {
                    return (
                        <div className="sub-contact">
                            <div className="content">
                                <p>{subContact.content}</p>
                            </div>
                            <div className="sub-contact-image">
                                <img src={process.env.REACT_APP_API_STATIC + '/' + subContact.image_path} />
                            </div>
                        </div>
                    )
                })} 
                </div>
            </div>
        </div>
    )
}

export default ContactDetails;

import React from 'react';

import { Link } from 'react-router-dom';

import './NewsSummary.scss';

import dateFormat from 'dateformat';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const NewsSummary = (props) => {

    const history = useHistory();

    const { t } = useTranslation();

    const handleReadmore = () => {
        history.push('/articles/' + props.news.id);
    }

    if(props.news.title === 0 || props.news.description === 0) {
        return (
        <div className="news-summary">
			<div className="news-image">
				<img src={process.env.REACT_APP_API_STATIC + props.news.image_path} />
			</div>
			<div className="news-details">
                <p>The content for this language is empty</p>
            </div>
		</div>
        )
    }

	return (
		<div className="news-summary wow fadeIn" onClick={handleReadmore}>
			<div className="news-image">
				<img src={process.env.REACT_APP_API_STATIC + props.news.image_path} />
			</div>
			<div className="news-details">
				<p className="news-date">{dateFormat(props.news.created_at, "dd/mm/yyyy")}</p>
				<p className="news-title">
                    {props.news.title
                        ?   props.news.title.substring(0,80) + (props.news.title.length > 100 ? " ..." : "")
                        :   <div>The content for this language is empty</div>
                    }

                </p>
                <p className="news-content">
                    {props.news.description
                        ?   props.news.description.substring(0,100) + (props.news.description.length > 100 ? " ..." : "")
                        :   <div>The content for this language is empty</div>
                    }
                </p>
				<p className="news-hashtag">{props.news.hashTag}</p>
				<Link to={'/articles/' + props.news.id} className="news-readmore">
                    {t('button.readmore')}
                </Link>
			</div>

		</div>
	)
}

export default NewsSummary;

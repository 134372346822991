import React, { useState } from 'react';
import './SearchBox.scss';

import { Redirect } from 'react-router-dom';

import 'font-awesome/css/font-awesome.min.css';

const SearchBox = () => {
    
    const [keyword, setKeyword ] = useState();

    const [isSearching, setIsSearching ] = useState(false);

    const handleInput = (e) => {
        setKeyword(e.target.value);
    }

    const handleSearch = () => {
        setIsSearching(true);
    }

    if(isSearching) {
        return (
            <Redirect to="/news"/>
        )
    }

	return (
		<div className="searchBox" >
			<input type= "text" onChange={handleInput} value={keyword}/>
            <a href={window.location.origin+'/news?keyword='+ keyword}>
                <i className="fa fa-search"></i>
            </a>
		</div>
	)
}

export default SearchBox;

import React, { useContext, useEffect, useRef, useState } from 'react';

import './SubContactEditor.scss';

import axios from 'axios';

import { CategoryContext } from '../../../contexts/CategoryContext';

const SubContactEditor = (props) => {

    const context = useContext(CategoryContext);

    const imageRef = useRef(null);

    const [ selectedFile, setSelectedFile ] = useState();

    const [subContact, setSubContact] = useState({
        id: '',
        content: '',
        imagePath: ''
    });

    useEffect(() => {
        props.handleSubContactInput(subContact, props.index);
    },[subContact.imagePath])

    useEffect(() => {
        setSubContact({
            id: props.subContact.id,
            imagePath: props.subContact.imagePath,
            content: props.subContact.content,
        });
        console.log(subContact);
        console.log(props.subContact);
    }, [props.subContact])

    useEffect(() => {
        props.handleSubContactInput(subContact, props.index);
    },[subContact])

    const inputsHandle = (e) => {
        setSubContact({...subContact, [e.target.name]: e.target.value});
        props.handleSubContactInput(subContact, props.index);
    }

    const uploadFileHandler = async (e) => {
        context.setIsLoading(true);
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_HOST}uploads`,
            headers: {
                jwtToken: context.jwtToken,
            },
            data: formData
        });

        const data = await response?.data;
        setSubContact({...subContact, ['imagePath']: data.fileName});
        context.setIsLoading(false);
    }

    const handleSelectedImageClick = () => {
        imageRef.current.click();
    }

    return(
        <>
        <div className="image-div" onClick={handleSelectedImageClick}>
        { subContact.imagePath ? (
            <div className="display-image" >
                <img src={process.env.REACT_APP_API_STATIC + subContact.imagePath} label="image"/>
            </div>
        ) : (<div className="unknown-image">Vui lòng chọn ảnh bìa</div>)}
        <input
            type="file"
            name="image"
            id="image"
            value={selectedFile}
            onChange={uploadFileHandler}
            ref={imageRef} hidden/>

        </div>
        <div className="input-div">
        <textarea
        name="content"
        value={subContact.content}
        onChange = {inputsHandle}
        />
        <a onClick={() => {if(window.confirm('Delete?' )) props.handleDeleteSubContact(subContact, props.index)}}>
            <i class="fa fa-minus-circle delete-icon-style"></i>
        </a>
        </div>
        </>
    )
}

export default SubContactEditor;

import React, { useState } from 'react';

import IntroductionDetails from '../../../introduction-details/IntroductionDetails';
import Contact from '../../../contact/Contact';

import './IntroductionLayout.scss';
import Navbar from '../../../navbar/Navbar';
import Footer from '../../../footer/Footer';

const IntroductionLayout = () => {

    return (
        <div className="main-page">
        <Navbar/>
        <div className="introduction-layout">
            <IntroductionDetails/>
            <div className="star-breakline revert"/>
            <Contact />
        </div>
        <Footer/>
        </div>
    )
}

export default IntroductionLayout;

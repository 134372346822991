import React, { useContext, useState } from 'react';

import { CategoryContext } from '../../contexts/CategoryContext';

import { Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import './Login.scss';
import Cookies from 'universal-cookie';

const Login = () => {

    let history = useHistory();

    const cookies = new Cookies();

    const context = useContext(CategoryContext);
   
    const [error, setError] = useState();

    const [ inputField, setInputField ] = useState({
        username: '',
        password: ''
    }) 

    const inputsHandler = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const submitButton = async () => {
        try {
            const resp = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}accounts/login`,
                data: inputField,
                withCredentials: true
            })

            console.log(resp.data);
            context.setUserContext(resp.data);
            cookies.set('userContext', JSON.stringify(resp.data), {
                path: '/'
            });
            history.push('/admin/');

        } catch(error) {
            console.log(error);
            if(error.response) {
                toast(error.response.data.message);
            }
        }
    }

    const onKeyUp = async (e) => {
        if (e.charCode === 13) {
            submitButton();
        }
    }

    if(context.username) {
        return (
            <Redirect to="/admin"/>
        )
    }

    return (
        <div className = "loginForm">
            <div className="container">

            <div className="logo" > 
                <img src={process.env.PUBLIC_URL + "/images/logo.svg"}/>
            </div>
            <div className="error-display">{error}</div> 
            <input
            type="text"
            name = "username"
            onChange = {inputsHandler}
            onKeyPress = {onKeyUp}
            placeholder = "Username"
            value = {inputField.username} />

            <input 
            type= "password"
            name = "password"
            onChange = {inputsHandler}
            onKeyPress = {onKeyUp}
            placeholder = "Password"
            value = {inputField.password} />

            <button onClick={submitButton} className="btn-type-1">Login</button>
            </div>
        </div>
    )

}

export default Login;

import React, { useEffect, useState, useContext } from 'react';
import './Categories.scss';

import { Link } from 'react-router-dom';

import { CategoryContext } from '../../contexts/CategoryContext';

import useFetch from '../../hooks/useFetch/index';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Caterogies = () => {

    const context = useContext(CategoryContext);

    const history = useHistory();

    const { t } = useTranslation();

    const {apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}categories`,
        method: 'get'
    });

    if(apiData.length == 0) {
        return <></>
    }

    const handleReadmore = (category) => {
        history.push('/categories/' + category.id);
    }

    return(
        <div className="categories">
        {
            apiData.map((category, index) => {
                return (
                    <div className="category-detail wow fadeIn">
                    <div className="container">
                    <div className="image">	
                    <img src={process.env.REACT_APP_API_STATIC + category.image_path} />

                    <i className="star-img"></i>
                    </div>
                    <div className="content">
                    <h4 onClick = {() => {handleReadmore(category)}}>{category.title}</h4>
                    <p>{category.description ? (category.description.substring(0,400) + (category.description.length > 400 ? " ..." : "")) : ""}</p>
                    <Link to={'/categories/' + category.id} >
                        <button className="btn-type-1">{t('button.readmore')}</button>
                    </Link>
                    </div>
                    </div>
                    </div>	
                ) 
            })
        }
        </div>
    )
}

export default Caterogies;

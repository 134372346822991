import React, {useEffect, useState, useContext } from 'react';

import { useParams } from 'react-router-dom';

import useFetch from '../../../hooks/useFetch/index';
import { CategoryContext } from '../../../contexts/CategoryContext';

import './AccountEditor.scss';

import { toast } from 'react-toastify';

import axios from 'axios';

const AccountEditor = () => {

    const { id } = useParams();

    const context = useContext(CategoryContext);

    const { apiData, isLoading } = useFetch({
        url: id ? `${process.env.REACT_APP_API_HOST}accounts/${id}` : "",
        method: 'get'
    });

    const [ inputField, setInputField ] = useState({
        username: '',
        password: '',
        retype_password: '',
    });
 
    useEffect(() => {
        if(apiData) {
            setInputField({
                username: apiData.username,
            })
        } else {
            setInputField({
                username: '',
                password: '',
                retype_password: ''
            })
        }
    },[apiData])


   const inputsHandle = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const submitForm = async () => {

        if(inputField.password !== inputField.retype_password) {
            toast("Mật khẩu không khớp");
            return;
        }

        try {
            const response = await axios({
                method: id ? 'put' : 'post',
                url: `${process.env.REACT_APP_API_HOST}accounts/${id ? id : ''}`,
                data: inputField,
                withCredentials: true
            })
            if(response.status === 200) {
                toast("Thêm người dùng thành công");
            }
        } catch(error) {
            toast(error.response.data.message);
        }
    }

    return (
        <div className="account-editor">
        <h3>{id ? 'Chỉnh sửa người dùng' : 'Thêm mới người dùng'}  </h3>
            <p>Tên tài khoản</p> 
            <input
            type="text"
            name="username"
            placeholder="username"
            value={inputField.username}
            onChange={inputsHandle}
            readOnly={id ? true : false}
            />
            <p>Mật khẩu</p>
            <input
            type="password"
            name="password"
            placeholder="password"
            value={inputField.passowrd}
            onChange={inputsHandle}
            />
            <p>Nhập lại mật khẩu</p>
            <input
            type="password"
            name="retype_password"
            placeholder="Nhập lại mật khẩu"
            value={inputField.retype_password}
            onChange={inputsHandle}
            />
        <button onClick={submitForm}>{id ? 'Cập nhât' : 'Thêm'}</button>
        </div>
    )
}

export default AccountEditor;

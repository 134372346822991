import React, { useContext } from 'react';

import './AccountManagementLayout.scss';
import AdminNavbar from '../../../admin-navbar/AdminNavbar';
import AccountManagement from '../../../account-management/AccountManagement';

import { CategoryContext } from '../../../../contexts/CategoryContext';
import { Redirect } from 'react-router';
import Login from '../../../login/Login';

const AccountManagementLayout = () => {
    const context = useContext(CategoryContext);

    if(!context.userContext) {
        return (
            <Login/>
        ) 
    }

    return (
        <>
            <div class="admin-layout">
                <AdminNavbar/>
                <AccountManagement/>
            </div>
        </>
    )
}

export default AccountManagementLayout;

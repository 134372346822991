import React, { useState } from 'react';

import ServiceDetails from '../../service-details/ServiceDetails';
import News from '../../news/news-sub/News';
import Contact from '../../contact/Contact';

import './ServiceDetailLayout.scss';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';

const ServiceDetailLayout = () => {

    return (
        <div className="main-page">
        <Navbar/>
        <div className="service-layout">
            <ServiceDetails/>
            <News/>  
            <Contact/>
        </div>
        <Footer/>
        </div>
    )
}

export default ServiceDetailLayout;

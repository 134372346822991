import React, { useContext } from 'react';

import { Redirect } from 'react-router-dom';

import { CategoryContext } from '../../../../contexts/CategoryContext';

import AdminNavbar from '../../../admin-navbar/AdminNavbar';
import AlbumManagement from '../../../album-management/AlbumManagement';

const AlbumManagementLayout = () => {

    const context = useContext(CategoryContext);

    if(!context.userContext) {
        return (<Redirect to='/login'/>)
    }

    return (
        <>
            <div className="admin-layout">
                <AdminNavbar/>
                <AlbumManagement/>
            </div>
        </>
    )

}

export default AlbumManagementLayout;

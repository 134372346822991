import React, { useState } from 'react';
import fakeData from '../../data/introduction.js';
import './Introduction.scss';

import { Link } from 'react-router-dom';

import useFetch from '../../hooks/useFetch/index';

import { useTranslation } from 'react-i18next';

const Introduction = () => {

    const { t } = useTranslation();

    const { apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}static_data/introduction`,
        method: 'get'
    });

    if(apiData.length == 0 ) {
        return <div>isLoading</div>
    }

	return (
		<>
		<div className="introduction wow fadeIn">
			<div className="background"/>
			<div className="container">
				<div className="image">
					<img src={process.env.PUBLIC_URL + "/images/1.jpg"} />
				</div>
				<div className="content">
					<h4>{apiData[0].title}</h4>
					<p>{apiData[0].description}</p>
						<Link to="/introduction" className="btn-type-1">{t('button.readmore')}</Link>
				</div>
			</div>
		</div>
		<div className="star-breakline" />
		</>
	)
}

export default Introduction;

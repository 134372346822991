import React from 'react';

import './ArticleManagement.scss';

import {BrowserRouter as Router, Route, Link } from 'react-router-dom';

import useFetch from '../../hooks/useFetch/index';

import ArticleEditor from './article-editor/ArticleEditor';

const ArticleManagement = () => {

    const { apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}articles`,
        method: 'get'
    });


    if(apiData.length == 0) {
        return <></>;
    }

    return (
        <>
            <Router>
            <div className="sub-navbar sub-navbar-article">
                <h3>Bài viết<Link to={"/admin/articles"} ><i className="fa fa-plus-circle add-icon-style"></i></Link></h3>
                <ul>
                {
                    apiData.map((article, index) => {
                        return (<li className={article.is_approved ? "is-approved" : "unapproved"} key={index}>
                            <Link to={'/admin/articles/' + article.id}><p>{(article.title) ? article.title : "Chưa có dữ liệu về ngôn ngữ này"}</p></Link>
                        </li>)
                    })
                }
                </ul>
            </div>
            <div className="main-editor">
                <Route path="/admin/articles/:id?" component={ArticleEditor}/>
            </div>
            </Router>
        </>
    )
}

export default ArticleManagement;

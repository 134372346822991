import React, { useContext } from 'react';

import {CategoryContext} from '../../../../contexts/CategoryContext';

import { Redirect } from 'react-router-dom';

import AdminNavbar from '../../../admin-navbar/AdminNavbar';

import IntroductionManagement from '../../../introduction-management/IntroductionManagement';

const IntroductionManagementLayout = () => {

    const context = useContext(CategoryContext);

    if(!context.userContext) {
        return <Redirect to="/login" />
    }
    return (
        <>
            <div class="admin-layout">
                <AdminNavbar/>
                <IntroductionManagement />
            </div>
        </>
    )

}

export default IntroductionManagementLayout;

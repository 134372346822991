import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';

import useFetch from '../../../hooks/useFetch/index';

import { CategoryContext } from '../../../contexts/CategoryContext';

import SubContactEditor from '../sub-contact-editor/SubContactEditor';

import ImageUploadHandler from '../../image-upload-handler/ImageUploadHandler';

import { toast } from 'react-toastify';

import generateRandomString from '../../../utils/generate-random-string';

import './ContactEditor.scss';

const ContactEditor = () => {

    const context = useContext(CategoryContext);

    const [ inputField, setInputField ] = useState({
        title: '',
        description: '',
        imagePath: ''
    });

    const [ subContacts, setSubContacts ] = useState([]);

    const { apiData, isLoading } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}static_data/contact`,
        method: 'get'
    });

    useEffect(() => {
        if(!apiData.length == 0) {
            setInputField({
                imagePath: apiData[0].image_path || '',
                title: apiData[0].title || '',
                description: apiData[0].description || ''
            })
            
            if(apiData[0].subArticles) {
                var subContacts = [];
                apiData[0].subArticles.map((subContact, index) => {
                    var input = {
                        id: subContact.id,
                        content: "",
                        imagePath: subContact.image_path || "null"
                    };
                    input.content = subContact.content || '';
                    subContacts.push(input);
                })
                setSubContacts(subContacts);
            } else {
                setSubContacts([]);
            }
        }
    }, [apiData, context.languageCode])

    const inputsHandle = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubmit = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_HOST}static_data/contact`,
                headers: {
                    languageCode: context.languageCode,
                    jwtToken: context.jwtToken
                },
                data: {...inputField, ['subContacts']: subContacts}
            })
            if(response.status === 200) {
                toast("Cập nhật thành công");
                context.setLatestPostRequest(generateRandomString(8));
            } else {
                toast("failed");
            }
        } catch (error) {
            toast(error?.response?.data.message);
        }
    }

   const addSubContactHandler = async () => {
        setSubContacts(oldSubContact => [...oldSubContact, {id: '', content: '', imagePath: ''}]);
    }


    const handleSubContactInput = (subContact, index) => {
        subContacts[index] = subContact;
    }

    const handleDeleteSubContact = async (subContact, index) => {
        if(!subContact.id || subContact.id.length === 0) {
            const newSubContacts = [...subContacts];
            newSubContacts.splice(index,1);
            setSubContacts(newSubContacts);
        } else {
            const response = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_HOST}sub_articles/${subContact.id}`,
                headers: {
                    jwtToken: context.jwtToken
                }
            })

            if(response.status === 200) {
                toast("Xóa thành công");
                context.setLatestPostRequest(generateRandomString(8));
            } else {
                toast("failed");
            }
        }
    }


    return (
        <>
        <div className="contact-editor">
        <h3>Chỉnh sửa phần liên hệ</h3>
        <h5>Tiêu đề</h5>
        <input
        type="text"
        name="title"
        onChange= {inputsHandle}
        placeholder = "Tiêu đề"
        value = {inputField.title} />
        <h5>Mô tả</h5>
        <textarea
        name="description"
        rows="10"
        onChange= {inputsHandle}
        placeholder = "Mô tả"
        value = {inputField.description} />
        <p>Ảnh nền</p>
        <ImageUploadHandler inputField={inputField} setInputField={setInputField}/> 
        </div>

        <div className="sub-contact-editor">
            <h3> Chi tiết liên hệ</h3>
            {subContacts.map((subContact, index) => {
                console.log(subContact)
                return (
                    <>
                        <SubContactEditor 
                        handleSubContactInput={handleSubContactInput}
                        subContact={subContact}
                        index={index}
                        handleDeleteSubContact={handleDeleteSubContact}/>
                    </>
                )
            })}
        <button className="btn-type-2" onClick={addSubContactHandler}>
            <i className="fa fa-plus"></i>
        </button>
        </div>

        <button className="btn-type-1" onClick={handleSubmit}>Cập nhật</button>
        </>
    )
}

export default ContactEditor;

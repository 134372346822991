import React from 'react';

import './Footer.scss';

const Footer = () => {
	return (
		<div className="footer">
			<div className="container">
				<div className="copyright">
					Copyright © 2020-2021 HIBC-Hanoi International Beauty Center. All rights reserved.
				</div>
				<ul>
					<li className="hotline">
                        <a href="tel:0866900800">Hotline 086 6900 800</a>
					</li>
					<li>
						<a href="https://www.facebook.com/B%C3%A1c-s%C4%A9-T%C6%B0-V%E1%BA%A5n-Ph%E1%BA%ABu-Thu%C3%A2%CC%A3t-Ta%CC%A3o-Hi%CC%80nh-Th%C3%A2%CC%89m-My%CC%83-Theo-Y%C3%AAu-C%E1%BA%A7u-HIBC-VIP-418487332038663/?ref=pages_you_manage" target="_blank">Facebook</a>
					</li>
					<li>
						<a>Instagram</a>
					</li>
					<li>
						<a>Map</a>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Footer;

import React, { useEffect, useState } from 'react';

import './Album.scss';

import { Link, useLocation } from 'react-router-dom';

import useFetch from '../../hooks/useFetch';

import AwesomeSlider from 'react-awesome-slider';

import data from './fakeData.js';
import '../../../node_modules/react-awesome-slider/dist/styles.css';

const Album = () => {

    const search = useLocation().search;
    
    const page = new URLSearchParams(search).get("page") || "1";

    const size = new URLSearchParams(search).get("size") || "6";

    const [totalPage, setTotalPage ] = useState(10);

    const { apiData } = useFetch({
        url: `${process.env.REACT_APP_API_HOST}albums/findByPage?page=${page}&size=${size}`,
        method: 'get'
    });

    useEffect(() => {
        window.scrollTo(0,0);
        if(apiData.results && apiData.results.length != 0) {
            setTotalPage(apiData.total / size);
        }
    }, [apiData, page, size])

    if(apiData.length == 0) {
        return <div>isLoading</div>
    }

    const paging = [];

    for(var i=0; i<totalPage; i++) {
        var pageNumber=i+1;
        paging.push(
            <Link to={'/album?page='+pageNumber+'&size='+size} className={'pageNumber' + ((pageNumber == page) ? " currentPage" : "")}>
                {pageNumber}
            </Link>
        );
    }

    return (
        <div className="albums-main container">
        <h3>Bộ sưu tập</h3>
        <div className="albums">
        {apiData.results.map(album => {
            return (
                <div className="album">
                    <AwesomeSlider bullets={false}>
                    { album.images.length > 0 ? (
                            album.images.map(image => {
                                return (
                                    <div>
                                        <img src={process.env.REACT_APP_API_STATIC + '/' + image}/>
                                    </div>
                                )
                            })) : (<></>)
                        }
                    </AwesomeSlider>
                    <h4>{album.title || ""}</h4>
                    <p>{album.description || ""}</p>
                </div>
            )
        })}


        </div>
        <div className="paging">
            trang <span>{paging}</span>
        </div>
        </div>
    )
}

export default Album;

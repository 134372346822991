import React, { useState } from 'react';
import Categories from '../../categories/Categories.js';
import Introduction from '../../introduction/Introduction.js';
import News from '../../news/news-sub/News.js';
import Contact from '../../contact/Contact.js';
import Navbar from '../../navbar/Navbar';
import './HomeLayout.scss';

import Footer from '../../footer/Footer.js';

const HomeLayout = () => {
    const [newsSize, setNewsSize] = useState(4);
  
    return (
        <div className="main-page">
        <Navbar/>
        <div className="home">
            <Categories/>
            <Introduction/>
            <News size={newsSize} />
            <div className="star-breakline revert" />
            <Contact/>
        </div>
        <Footer/>
        </div>
    )
}

export default HomeLayout;

import React, { useContext, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import {Sortable, Swap } from'sortablejs/modular/sortable.core.esm';

import axios from 'axios';

import { toast } from 'react-toastify';

import { CategoryContext } from '../../../contexts/CategoryContext';

import generateRandomString from '../../../utils/generate-random-string';

const ServiceList = (props) => {


    const context = useContext(CategoryContext);

    const [services, setServices] = useState([]);

    useEffect(() => {
        setServices(props.category.services);
        console.log(services);
    }, props.category.services)

    if(services.length === 0) {
        return <div className="service"> không có dữ liệu về dịch vụ </div>;
    }

    const el = document.getElementById(props.category.id);

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
 
    if(el) {
        const sortable = new Sortable.create(el, {
            group: `g${props.category.id}s`,
            sort: false,
            swap: true,
            draggable: '.service',
            ghostClass: 'sortable-ghost',
            chosenClass: 'sortable-chosen',
            dragClass: 'sortable-drag',

            onSort: async function(evt) {
                try {
                    const input = {
                        fromService: props.category.services[evt.oldIndex],
                        toService: props.category.services[evt.newIndex],
                        fromIndex: evt.oldIndex,
                        toIndex: evt.newIndex
                    }
                    const response = await axios({
                        method: 'post',
                        url: `${process.env.REACT_APP_API_HOST}services/changeOrder`,
                        data: input,
                        withCredentials: true
                    })

                    const status = await response.data;

                    if(status) {
                        //toast("Cập nhật thành công");
                        //context.setLatestPostRequest(generateRandomString(8));
                        window.location.reload();
                    }
                } catch(error) {
                    toast(error?.response?.data.message || "unknown error");
                }

            }
        })

    }

    return (
        <div className="services" id={props.category.id}>
        {services.map((service, index) => {
                return <p className="service" key={index}>
                    <Link to={'/admin/categories/' + props.category.id + '/services/' + service.id}>
                    {service.title || "Chưa có dữ liệu"}
                    </Link>
                    <a onClick={() => {if(window.confirm("Bạn có chắc chắn muốn xóa?")) props.handleDeleteService(service.id)}} value={service.id}>
                    <i class="fa fa-minus-circle delete-icon-style"></i>
                    </a>
                    </p>
            })}
        </div>
    )
}

export default ServiceList;
